import { gql } from "@apollo/client";

export type IncomesPerMethod = {
  Cash: number;
  CreditCard: number;
  DebitCard: number;
  MercadoPago: number;
  PrepaidCard: number;
  Edenred: number;
  CuentaDNI: number;
  Fee: number;
  Custom: number;
  TiendaNubeWallet: number;
  Nave: number;
  BankTransfer: number;
  Check: number;
  eCheck: number;
  CheckingAccount: number;
};

export type Summary = {
  dateFrom: Date;
  dateTo: Date;
  stores: number;
  customers: number;
  employees: number;
  suppliers: number;
  categories: number;
  sales: number;
  priceLists: number;
  incomes: number;
  profit: number;
  avgTicket: number;
  money: {
    type: string;
    amount: number;
  }[];
  incomesPerMethod: IncomesPerMethod;
};

export type SummaryByMonth = {
  date: string;
  month: string;
  monthN: number;
  year: number;
  income: number;
  profit: number;
};

export const emptyData = {
  dateFrom: new Date(),
  dateTo: new Date(),
  stores: 0,
  customers: 0,
  employees: 0,
  sales: 0,
  incomes: 0,
  incomesPerMethod: {},
} as Summary;

export const SUMMARY = gql`
  query summary($dateFrom: String, $dateTo: String, $storeId: Int) {
    data: summary(dateFrom: $dateFrom, dateTo: $dateTo, storeId: $storeId) {
      dateFrom
      dateTo
      customers
      employees
      incomes
      incomesPerMethod {
        Cash
        CreditCard
        DebitCard
        MercadoPago
        PrepaidCard
        Edenred
        CuentaDNI
        RappiPay
        Nave
        OpenpayBBVA
        GetNet
        BankTransfer
        CheckingAccount
        ViuMi
        eCheck
        Check
      }
      money {
        type
        amount
      }
      avgTicket
      sales
      stores
      profit
    }
  }
`;

export const TAX_AMOUNTS = gql`
  query taxAmounts($dateFrom: String, $dateTo: String, $storeId: Int) {
    data: taxAmounts(dateFrom: $dateFrom, dateTo: $dateTo, storeId: $storeId)
  }
`;

export const SUMMARY_BY_MONTH = gql`
  query byMonth($year: Int, $storeId: Int) {
    data: byMonth(year: $year, storeId: $storeId) {
      date
      month
      monthN
      year
      income
      profit
    }
  }
`;

export const CHECKING_BALANCE = gql`
  query checkingBalance($storeId: Int) {
    data: checkingBalance(storeId: $storeId) {
      debit
      credit
    }
  }
`;
