import { useMutation } from "@apollo/client";
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CLoadingButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CRow,
} from "@coreui/react-pro";
import { useFormik } from "formik";
import { sortBy } from "lodash";
import {
  ForwardRefRenderFunction,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import Api from "src/api";
import { PaymentMethod, Sale } from "src/api/sales";
import SmartSelect from "src/components/SmartSelect";
import {
  calculatePercentage,
  calculateValue,
  decimalNumber,
} from "src/helpers/numbers";
import { getRegisterPaymentMethods } from "src/helpers/payments";
import { ForwardedModalProps } from "src/types";

enum Step {
  SelectPaymentMethod = 0,
  AddDiscount = 1,
  AddPaymentAmount = 2,
  MercadoPago = 3,
}

const AddPaymentModal: ForwardRefRenderFunction<
  ForwardedModalProps,
  { refetch: () => void; rest: number }
> = ({ refetch, rest }, ref) => {
  const [step, setStep] = useState(Step.SelectPaymentMethod);
  const [visible, setVisible] = useState<boolean>(false);
  const [sale, setSale] = useState<Sale>();

  const [mutation, { loading: adding }] = useMutation(
    Api.Sales.ADD_SALE_PAYMENTS,
    {
      onCompleted: () => {
        refetch();
        handleDismiss();
      },
    }
  );

  const handleDismiss = () => {
    setVisible(false);
    setSale(undefined);
  };

  const formik = useFormik({
    initialValues: {
      paymentMethod: "",
      discount: 0,
      discountAmount: 0,
      amount: rest,
      cardNumber: "",
      bankPromotionId: null,
      bankPromotionData: null,
    },
    onSubmit: (values) => {
      if (sale && !adding) {
        const payments = [
          {
            type: values.paymentMethod,
            amount: values.amount,
            cardNumber: values.cardNumber,
            bankPromotionId: values.bankPromotionId,
            bankPromotionData: values.bankPromotionData,
          },
        ];

        if (values.discountAmount > 0) {
          payments.push({
            type: PaymentMethod.Discount,
            amount: values.discountAmount,
            cardNumber: "",
            bankPromotionId: null,
            bankPromotionData: null,
          });
        }

        mutation({
          variables: {
            id: sale.id,
            payments,
          },
        });
      }
    },
  });

  useImperativeHandle(
    ref,
    () => ({
      close: () => {
        handleDismiss();
        formik.resetForm();
      },
      open: (s: Sale) => {
        setSale(s);
        setVisible(true);
      },
    }),
    [formik]
  );

  const filteredPaymentMethods = useMemo(
    () =>
      sortBy(
        getRegisterPaymentMethods(sale?.register?.config, sale).map(
          (paymentMethod) => ({
            name: paymentMethod.name,
            value: paymentMethod.type,
          })
        ),
        "name"
      ),
    [sale]
  );

  const handleNextStep = useCallback(() => {
    if (step === Step.SelectPaymentMethod && !!formik.values.paymentMethod) {
      setStep(step + 1);
    }

    if (step === Step.AddDiscount) {
      formik.setFieldValue("amount", rest - formik.values.discountAmount);
      setStep(step + 1);
    }

    if (step === Step.AddPaymentAmount) {
      formik.handleSubmit();
    }
  }, [formik, rest, step]);

  const handleSubmit = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        handleNextStep();
      }
    },
    [handleNextStep]
  );

  useEffect(() => {
    if (visible) {
      document.addEventListener("keypress", handleSubmit);
    }

    return () => {
      document.removeEventListener("keypress", handleSubmit);
    };
  }, [handleSubmit, visible]);

  return (
    <CForm onSubmit={formik.handleSubmit}>
      <CModal alignment="center" visible={visible} onClose={handleDismiss}>
        <CModalHeader closeButton>
          Añadir Pago a la venta #{sale?.id}
        </CModalHeader>
        <CModalBody>
          <CRow>
            <CFormLabel>Metodo de Pago</CFormLabel>
            <SmartSelect
              options={filteredPaymentMethods}
              name="paymentMethod"
              placeholder="Seleccione metodo de pago"
              resultsCount={filteredPaymentMethods.length}
              value={formik.values.paymentMethod}
              onChange={(e) => {
                formik.setFieldValue("paymentMethod", e);
              }}
            />
          </CRow>
          {step >= Step.AddDiscount && (
            <CRow className="mt-3">
              <CCol sm={6}>
                <CFormLabel>Descuento (%)</CFormLabel>
                <CFormInput
                  name="discount"
                  value={formik.values.discount}
                  onChange={(e) => {
                    const value = e.target.value;
                    const val = decimalNumber(value, 100);

                    if (val === null) {
                      formik.setFieldValue(
                        "discountAmount",
                        formik.values.discountAmount
                      );
                      return;
                    }

                    formik.setFieldValue("discount", val);
                    formik.setFieldValue(
                      "discountAmount",
                      calculateValue(rest, Number(val))
                    );
                  }}
                />
              </CCol>
              <CCol sm={6}>
                <CFormLabel>Descuento ($)</CFormLabel>
                <CFormInput
                  name="discountAmount"
                  value={formik.values.discountAmount}
                  onChange={(e) => {
                    const value = e.target.value;
                    const val = decimalNumber(value, rest);

                    if (val === null) {
                      formik.setFieldValue(
                        "discountAmount",
                        formik.values.discountAmount
                      );
                      return;
                    }

                    formik.setFieldValue("discountAmount", val);
                    formik.setFieldValue(
                      "discount",
                      calculatePercentage(rest, Number(val))
                    );
                  }}
                />
              </CCol>
            </CRow>
          )}

          {step >= Step.AddPaymentAmount && (
            <CRow className="mt-3">
              <CCol sm={6}>
                <CFormLabel>Monto</CFormLabel>
                <CFormInput
                  name="amount"
                  value={formik.values.amount}
                  onChange={(e) => {
                    const value = e.target.value;
                    const val = Math.abs(Number(value));

                    if (
                      isNaN(val) ||
                      (val > rest &&
                        formik.values.paymentMethod !== PaymentMethod.Cash)
                    ) {
                      return;
                    }

                    formik.setFieldValue("amount", value);
                  }}
                />
              </CCol>

              {![
                PaymentMethod.Cash,
                PaymentMethod.CustomerDiscount,
                PaymentMethod.Discount,
                PaymentMethod.BankTransfer,
                PaymentMethod.CheckingAccount,
                PaymentMethod.Check,
                PaymentMethod.eCheck,
              ].includes(formik.values.paymentMethod as PaymentMethod) && (
                <CCol sm={6}>
                  <CFormLabel>Número de Tarjeta</CFormLabel>
                  <CFormInput
                    name="creditNumber"
                    value={formik.values.cardNumber}
                    onChange={(e) => {
                      formik.setFieldValue("cardNumber", e.target.value);
                      formik.setFieldValue("maskedCardNumber", e.target.value);
                    }}
                  />
                </CCol>
              )}

              {[PaymentMethod.eCheck, PaymentMethod.Check].includes(
                formik.values.paymentMethod as PaymentMethod
              ) && (
                <CCol sm={6}>
                  <CFormLabel>Número de Cheque</CFormLabel>
                  <CFormInput
                    name="cardNumber"
                    value={formik.values.cardNumber}
                    onChange={formik.handleChange}
                  />
                </CCol>
              )}
            </CRow>
          )}
        </CModalBody>
        <CModalFooter>
          <CButton
            size="sm"
            color="secondary"
            type="button"
            onClick={() => handleDismiss()}
          >
            Cancelar
          </CButton>

          <CLoadingButton
            loading={adding}
            size="sm"
            color="success"
            type="button"
            onClick={handleNextStep}
          >
            {step === Step.AddPaymentAmount ? "Anadir Pago" : "Siguiente Paso"}
          </CLoadingButton>
        </CModalFooter>
      </CModal>
    </CForm>
  );
};

export default forwardRef(AddPaymentModal);
