import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CSmartTable,
  CRow,
  CButton,
  CLink,
  CLoadingButton,
  CTableBody,
  CTableDataCell,
  CTableRow,
  CTable,
  CTooltip,
} from "@coreui/react-pro";

import { useMutation, useQuery } from "@apollo/client";

import {
  CreditNote,
  PaymentMethod,
  Sale,
  SaleInvoice,
  SaleItem,
  SaleOrigin,
  SaleStatus,
} from "src/api/sales";
import { dateFormat } from "src/helpers/dates";
import { formatCurrency } from "src/helpers/numbers";
import Api from "src/api";
import { ForwardedModalProps, GraphQLFind, GraphQLMeta } from "src/types";
import { AppLoader } from "src/components/Loader/Loader";

import InvoicingModal from "./components/InvoicingModal";
import { Customer } from "src/api/customers";
import CustomersModal from "./components/CustomersModal";
import CalendarModal, {
  ForwardedCalendarModalProps,
} from "src/components/CalendarModal";
import { useSale } from "src/hooks/useSale";
import { logEvent } from "src/helpers/analytics";
import { StoreType } from "src/api/stores";
import DeleteSaleModal from "./components/DeleteSaleModal";
import Toast, { ForwardedToastProps } from "src/components/Toast";
import {
  calculateSaleRest,
  getCustomer,
  hasInvoiceAndCreditNotePair,
  mapPromotionItems,
} from "src/helpers/sales";
import SendInvoiceModal from "./components/SendInvoiceModal";
import CreditNoteModal from "./components/CreditNoteModal";
import {
  Column,
  Item,
} from "@coreui/react-pro/dist/esm/components/smart-table/types";
import SalePaymentsCard from "src/components/SalePaymentsCard";
import {
  ArrowRight,
  CheckIcon,
  CloudDownload,
  CloudUpload,
  Redo,
  Send,
  XIcon,
} from "lucide-react";
import CancelSaleModal from "./components/CancelSaleModal";
import AddPaymentModal from "./components/AddPaymentModal";
import { useUserStore } from "src/store/users";
import classNames from "classnames";

enum PrintType {
  Draft = "Draft",
  Ticket = "Ticket",
}

const CustomerRow = ({
  sale,
  printType,
  onAssign,
}: {
  sale: Sale;
  printType?: PrintType;
  onAssign: () => void;
}) => {
  const navigate = useNavigate();
  const customer = sale.customer;
  const hasCustomer = customer?.id;

  return (
    <>
      Cliente:
      {hasCustomer ? (
        <>
          {" "}
          <CLink onClick={() => navigate(`/customers/${customer.id}`)}>
            {getCustomer(customer)}
          </CLink>
        </>
      ) : (
        <>
          {" Consumidor Final"}
          {sale.deletedAt === null && (
            <span
              className={classNames({
                "no-print": printType === PrintType.Ticket,
                "no-print-draft": printType === PrintType.Draft,
              })}
            >
              {" - "}
              <CLink onClick={() => onAssign()}>Asignar cliente</CLink>
            </span>
          )}
        </>
      )}
    </>
  );
};

const SaleScreen = () => {
  const { hasPermission, user } = useUserStore();
  const navigate = useNavigate();
  const params = useParams();
  const [downloading, setDownloading] = useState<boolean>(false);
  const [printType, setPrintType] = useState<PrintType>();
  const { calculateItem, calculateSummary } = useSale();
  const saleId = Number(params.id);
  const calendarModalRef = useRef<ForwardedCalendarModalProps>(null);
  const customersModalRef = useRef<ForwardedModalProps>(null);
  const invoicingModalRef = useRef<ForwardedModalProps>(null);
  const addPaymentModalRef = useRef<ForwardedModalProps>(null);
  const cancelSaleModalRef = useRef<ForwardedModalProps>(null);
  const deleteSaleModalRef = useRef<ForwardedModalProps>(null);
  const sendInvoiceModalRef = useRef<ForwardedModalProps>(null);
  const creditNoteModalRef = useRef<ForwardedModalProps>(null);
  const toastRef = useRef<ForwardedToastProps>(null);

  if (!saleId) {
    navigate("/sales");
  }

  useEffect(() => {
    if (printType) {
      window.print();

      setPrintType(undefined);
    }
  }, [printType]);

  const { data: sale, refetch } = useQuery<GraphQLFind<Sale>>(
    Api.Sales.GET_SALE,
    {
      fetchPolicy: "no-cache",
      variables: {
        id: saleId,
      },
      onError: () => {
        navigate(-1);
      },
    }
  );
  const { data: customers } = useQuery<GraphQLMeta<Customer>>(
    Api.Customers.LIST_CUSTOMERS,
    {
      skip: !sale?.data,
      variables: {
        filters: {
          limit: 0,
          companyId: sale?.data?.store.company.id,
          storeId: sale?.data?.store.id,
        },
      },
    }
  );
  const [changeDateMutation, { loading }] = useMutation(Api.Sales.UPDATE_SALE, {
    onCompleted: () => {
      customersModalRef.current?.close();

      window.location.reload();
    },
  });
  const [attachMutation, { loading: attaching }] = useMutation(
    Api.Sales.ATTACH_INVOICE,
    {
      onCompleted: () => {
        window.location.reload();
      },
    }
  );
  const [finishSaleMutation, { loading: finishing }] = useMutation(
    Api.Sales.FINISH_SALE,
    {
      onCompleted: () => {
        window.location.reload();
      },
    }
  );

  const metaData = useMemo(() => {
    if (!sale?.data) {
      return [];
    }

    const fee = sale.data.payments.find((p) => p.type === PaymentMethod.Fee);
    const summary = calculateSummary(sale.data, fee?.amount ?? 0);

    const items: any[] = [
      {
        right: {
          label: "Subtotal",
          value: formatCurrency(summary.subtotal),
        },
      },
    ];

    items.push({
      right: {
        label: "IVA",
        value: formatCurrency(summary.tax),
      },
    });

    if (summary.recharge > 0) {
      items.push({
        right: {
          label: `Recargo`,
          value: formatCurrency(summary.recharge),
        },
      });
    }

    if (summary.itemsDiscount > 0) {
      items.push({
        right: {
          label: `Descuento en Items`,
          value: `- ${formatCurrency(summary.itemsDiscount)}`,
        },
      });
    }

    if (summary.customerDiscount > 0) {
      items.push({
        right: {
          label: `Descuento de Cliente`,
          value: `- ${formatCurrency(summary.customerDiscount)}`,
        },
      });
    }

    if (summary.saleDiscount > 0) {
      items.push({
        right: {
          label: `Descuento ${summary.saleDiscount > 0 ? " en Pago" : ""}`,
          value: `- ${formatCurrency(summary.saleDiscount)}`,
        },
      });
    }

    if (fee) {
      items.push({
        right: {
          label: `Comision`,
          value: `- ${formatCurrency(fee.amount)}`,
        },
      });
    }

    items.push({
      right: {
        label: "Total",
        value: formatCurrency(summary.total),
      },
    });

    return items;
  }, [calculateSummary, sale?.data]);

  const downloadInvoice = async (
    invoice: SaleInvoice | CreditNote,
    save = false,
    type: "credit" | "invoice" = "invoice"
  ) => {
    if (!sale || downloading) {
      return null;
    }

    try {
      setDownloading(true);

      logEvent("sale.invoice.download", {
        saleId: sale.data.id,
        cae: invoice.afipInvoice.cae,
      });

      const response = await Api.Sales.DOWNLOAD_SALE_INVOICE(
        sale.data.id,
        invoice.afipInvoice.cae,
        type
      );

      if (!save) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", response.fileName);
        document.body.appendChild(link);
        link.click();

        logEvent("sale.invoice.downloaded", {
          saleId: sale.data.id,
          cae: invoice.afipInvoice.cae,
          filename: response.fileName,
        });

        link.remove();
      } else {
        return response;
      }
    } catch (e: any) {
      logEvent("sale.invoice.download.error", {
        saleId: sale.data.id,
        cae: invoice.afipInvoice.cae,
        error: e.message,
      });
    } finally {
      setDownloading(false);
    }
  };

  if (!sale?.data) {
    return <AppLoader />;
  }

  const totalInvoices = [
    ...sale.data.invoices,
    ...sale.data.electronicInvoices.map((i) => ({
      ...i,
      afipInvoice: {
        ...i,
        ...i.voucher,
      },
    })),
  ];

  const hasInvoiceAndCreditNote = hasInvoiceAndCreditNotePair(totalInvoices);
  const { total, rest: summaryRest } = calculateSummary(sale.data);

  const canUpdate = hasPermission("UPDATE_SALE");
  const canDelete = hasPermission("DELETE_SALE");

  const hasUTID = sale?.data?.store.utid;
  const hasNoInvoices = totalInvoices.length === 0;
  const hasAFIPError = sale?.data?.afipError === true;
  const isCompletedSale = sale?.data?.status === SaleStatus.Completed;
  const currentSale = {
    ...sale?.data,
    items: [
      ...(sale?.data.items.filter((i) => !i.promotion) ?? []),
      ...(mapPromotionItems(sale?.data.items ?? []) as unknown as SaleItem[]),
    ],
  };
  const isDeletedSale = currentSale.deletedAt !== null;
  const isStoreSale = currentSale.store.type === StoreType.Store;
  const rest = calculateSaleRest(total, sale?.data.payments ?? []);
  const customer = currentSale.customer;

  const countablePaymentAmount = currentSale.payments.reduce(
    (p, c) =>
      p +
      ([PaymentMethod.Discount, PaymentMethod.CustomerDiscount].includes(c.type)
        ? 0
        : c.amount),
    0
  );

  const isPartialSale =
    currentSale.origin === SaleOrigin.TiendaNube &&
    (total !== summaryRest || total !== countablePaymentAmount);

  const canCreateSaleInvoice =
    hasPermission("CREATE_SALE") &&
    !isDeletedSale &&
    isCompletedSale &&
    hasUTID &&
    (hasNoInvoices || hasInvoiceAndCreditNote) &&
    !hasAFIPError &&
    total > 0 &&
    !isPartialSale;

  const canDeleteOrCancelSale =
    canUpdate && canDelete && isStoreSale && !isDeletedSale;

  const canChangeSaleDate = canUpdate && isStoreSale && !isDeletedSale;

  const discountPayments = currentSale.payments.filter((p) =>
    [PaymentMethod.Discount, PaymentMethod.CustomerDiscount].includes(p.type)
  );

  const salePayments = currentSale.payments.filter(
    (p) =>
      ![PaymentMethod.Discount, PaymentMethod.CustomerDiscount].includes(p.type)
  );

  if (discountPayments.length > 0) {
    salePayments.push({
      id: 0,
      saleId: discountPayments[0].saleId,
      payedInMonths: 1,
      type: PaymentMethod.Discount,
      amount: discountPayments.reduce((prev, curr) => prev + curr.amount, 0),
    });
  }

  const creditNotes = sale.data.invoices.filter((i) => i.relatedTo);
  const filteredInvoices = totalInvoices
    .filter((i) => i.afipInvoice.total > 0 && i.relatedTo === null)
    .map((i) => ({
      ...i,
      creditNotes: creditNotes.filter((c) => c.relatedTo === i.id),
    }));

  const columns: Column[] = [];

  if (!printType || printType === PrintType.Ticket) {
    columns.push(
      ...[
        {
          key: "price",
          label: "Precio",
          _props: { className: "text-right" },
        },
        {
          key: "tax",
          label: "IVA",
          _props: { className: "text-right" },
        },
        {
          key: "discount",
          label: "Descuento / Recargo",
          _props: { className: "text-right" },
        },
        {
          key: "subtotal",
          label: "Subtotal",
          _props: { className: "text-right" },
        },
      ]
    );
  }

  return (
    <>
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader className="flex">
              <CCol sm={3} className="d-flex align-items-center">
                Venta #{currentSale.id}
              </CCol>
              <CCol sm={9} className="d-flex justify-content-end">
                {!currentSale.deletedAt && (
                  <CButton
                    size="sm"
                    className={classNames("text-white", {
                      "no-print": printType === PrintType.Ticket,
                      "no-print-draft": printType === PrintType.Draft,
                    })}
                    color="warning"
                    onClick={() => {
                      setPrintType(PrintType.Draft);
                    }}
                  >
                    Imprimir Remito
                  </CButton>
                )}

                {isCompletedSale && !currentSale.deletedAt && (
                  <CButton
                    size="sm"
                    className={classNames("ml-2 text-white", {
                      "no-print": printType === PrintType.Ticket,
                      "no-print-draft": printType === PrintType.Draft,
                    })}
                    color="info"
                    onClick={() => {
                      setPrintType(PrintType.Ticket);
                    }}
                  >
                    Imprimir Ticket
                  </CButton>
                )}

                {canCreateSaleInvoice && (
                  <CButton
                    size="sm"
                    className={classNames("ml-2", {
                      "no-print": printType === PrintType.Ticket,
                      "no-print-draft": printType === PrintType.Draft,
                    })}
                    color="primary"
                    onClick={() =>
                      invoicingModalRef.current?.open(
                        sale.data,
                        customers?.data.data ?? []
                      )
                    }
                  >
                    Facturar Venta
                  </CButton>
                )}

                {canChangeSaleDate && (
                  <CButton
                    size="sm"
                    className={classNames("ml-2", {
                      "no-print": printType === PrintType.Ticket,
                      "no-print-draft": printType === PrintType.Draft,
                    })}
                    color="success"
                    onClick={() =>
                      calendarModalRef.current?.open(new Date(sale.data.date))
                    }
                  >
                    Cambiar fecha
                  </CButton>
                )}

                {canDeleteOrCancelSale && (
                  <CLoadingButton
                    size="sm"
                    className={classNames("ml-2", {
                      "no-print": printType === PrintType.Ticket,
                      "no-print-draft": printType === PrintType.Draft,
                    })}
                    color="danger"
                    onClick={() => {
                      if (isCompletedSale) {
                        deleteSaleModalRef.current?.open(sale.data);
                      } else {
                        cancelSaleModalRef.current?.open(sale.data);
                      }
                    }}
                  >
                    {isCompletedSale ? "Eliminar Venta" : "Cancelar Venta"}
                  </CLoadingButton>
                )}

                {isCompletedSale && currentSale.deletedAt && (
                  <span
                    className={classNames({
                      "no-print": printType === PrintType.Ticket,
                      "no-print-draft": printType === PrintType.Draft,
                    })}
                  >
                    Venta {isCompletedSale ? "eliminada" : "cancelada"} el{" "}
                    {dateFormat(
                      currentSale.deletedAt,
                      "dd/MM/yyyy 'a las' HH:mm 'hs'"
                    )}
                  </span>
                )}

                {!isCompletedSale && !isDeletedSale && rest > 0 && (
                  <CLoadingButton
                    size="sm"
                    className={classNames("ml-2", {
                      "no-print": printType === PrintType.Ticket,
                      "no-print-draft": printType === PrintType.Draft,
                    })}
                    color="info"
                    onClick={() => {
                      addPaymentModalRef.current?.open(sale.data);
                    }}
                  >
                    Añadir Pago
                  </CLoadingButton>
                )}

                {!isCompletedSale && !isDeletedSale && rest === 0 && (
                  <CLoadingButton
                    size="sm"
                    className={classNames("ml-2", {
                      "no-print": printType === PrintType.Ticket,
                      "no-print-draft": printType === PrintType.Draft,
                    })}
                    color="info"
                    loading={finishing}
                    disabled={finishing}
                    onClick={() => {
                      finishSaleMutation({
                        variables: {
                          id: sale.data.id,
                        },
                      });
                    }}
                  >
                    Finalizar Venta
                  </CLoadingButton>
                )}
              </CCol>
            </CCardHeader>
            <CCardBody>
              <CRow className="align-items-center justify-content-center mb-2">
                <CCol sm={6}>
                  <CustomerRow
                    sale={currentSale}
                    printType={printType}
                    onAssign={() =>
                      customersModalRef.current?.open(currentSale)
                    }
                  />
                </CCol>
                <CCol sm={6} className="text-right">
                  Fecha y hora:{" "}
                  {dateFormat(currentSale.date, "dd/MM/yyyy HH:mm 'hs'")}
                </CCol>
              </CRow>
              <hr className="pt-0 mt-0" />
              <CSmartTable
                itemsPerPage={currentSale.items.length}
                items={
                  currentSale.items.map((item) => ({
                    ...item,
                    summary: calculateItem(item),
                  })) || []
                }
                columns={[
                  {
                    key: "quantity",
                    label: "Cantidad",
                    _props: { className: "text-left" },
                  },
                  { key: "product", label: "Producto" },
                  ...columns,
                ]}
                scopedColumns={{
                  product: (item: SaleItem) => <td>{item.product.name}</td>,
                  quantity: (item: SaleItem) => (
                    <td className="text-left">{item.quantity}</td>
                  ),
                  price: (item: SaleItem) => (
                    <td className="text-right">
                      {formatCurrency(item.summary.price)}
                    </td>
                  ),
                  tax: (item: SaleItem) => (
                    <td className="text-right">
                      {formatCurrency(item.summary.tax)}
                    </td>
                  ),
                  discount: (item: SaleItem) => (
                    <td className="text-right">
                      {item.summary.discount > 0
                        ? `- ${formatCurrency(item.summary.discount)}`
                        : item.summary.recharge > 0
                        ? formatCurrency(item.summary.recharge)
                        : formatCurrency(0)}
                    </td>
                  ),
                  subtotal: (item: SaleItem) => (
                    <td className="text-right">
                      {formatCurrency(item.summary.total)}
                    </td>
                  ),
                }}
                tableProps={{
                  striped: true,
                }}
              />

              <div className="only-print-draft">
                <span>
                  Dirección de Entrega: {customer?.streetName}{" "}
                  {customer?.streetNumber}, {customer?.city},{" "}
                  {customer?.province}, CP: {customer?.zipCode}
                </span>
              </div>

              <div className="no-print-draft">
                <hr className="pt-0 mt-0" />

                {metaData.map((metaRow, index) => (
                  <CRow
                    className="align-items-center justify-content-center mb-2"
                    key={index}
                  >
                    <CCol sm={6} style={{ paddingLeft: 12 }}>
                      {metaRow.left ? `${metaRow.left}` : ""}
                    </CCol>
                    <CCol sm={6} className="row justify-content-end">
                      {metaRow.right ? (
                        <>
                          <CCol
                            sm={6}
                            className="px-0 text-right font-weight-bold text-uppercase"
                          >
                            {metaRow.right.label}
                          </CCol>
                          <CCol
                            sm={6}
                            className="text-right font-weight-bold"
                            style={{ paddingRight: 12 }}
                          >
                            {metaRow.right.value}
                          </CCol>
                        </>
                      ) : (
                        ""
                      )}
                    </CCol>
                  </CRow>
                ))}
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow className="mt-4 no-print-draft">
        <CCol sm={4}>
          <SalePaymentsCard
            origin={sale.data.origin}
            payments={salePayments.reverse()}
            register={sale.data.register}
            saleStatus={sale.data.status}
            editable
          />
        </CCol>

        {filteredInvoices.length > 0 && (
          <CCol sm={8}>
            <CCard>
              <CCardHeader>
                <CCol sm={12}>Facturas</CCol>
              </CCardHeader>
              <CCardBody>
                <CSmartTable
                  itemsPerPage={filteredInvoices.length}
                  items={filteredInvoices}
                  columns={[
                    { key: "cae", label: "CAE" },
                    { key: "type", label: "Tipo de Factura" },
                    {
                      key: "date",
                      label: "Fecha",
                      _props: { className: "text-right" },
                    },
                    {
                      key: "emailSent",
                      label: "Mail Enviado",
                      _props: { className: "text-center" },
                    },
                    {
                      key: "actions",
                      label: "Acciones",
                      _props: { className: "text-right" },
                    },
                  ]}
                  scopedColumns={{
                    cae: (invoice: SaleInvoice) => (
                      <td style={{ width: 182 }}>{invoice.afipInvoice.cae}</td>
                    ),
                    date: (invoice: SaleInvoice) => (
                      <td className="text-right">
                        {dateFormat(invoice.createdAt, "dd/MM/yyyy HH:mm")}
                      </td>
                    ),
                    type: (invoice: SaleInvoice) => (
                      <td>{invoice.afipInvoice?.voucher?.name}</td>
                    ),
                    emailSent: (invoice: SaleInvoice) => (
                      <td className="text-center" style={{ width: 120 }}>
                        {invoice.emailSent ? (
                          <CTooltip
                            content={`Enviado el ${dateFormat(
                              invoice.emailSent,
                              "dd/MM/yyyy HH:mm"
                            )}`}
                          >
                            <CheckIcon />
                          </CTooltip>
                        ) : (
                          <XIcon />
                        )}
                      </td>
                    ),
                    amount: (invoice: SaleInvoice) => (
                      <td className="text-right">
                        {formatCurrency(invoice.afipInvoice.total)}
                      </td>
                    ),
                    actions: (invoice: SaleInvoice) => {
                      const canCreateCreditNote =
                        invoice.creditNotes.length === 0;

                      return (
                        <td className="text-right">
                          <CTooltip
                            content={<span>Enviar Factura por Email</span>}
                          >
                            <CButton
                              size="sm"
                              color="info"
                              className="mr-2"
                              onClick={() => {
                                if (
                                  sale.data.deletedAt === null ||
                                  user?.isAdmin
                                ) {
                                  sendInvoiceModalRef.current?.open(invoice);
                                } else {
                                  alert(
                                    "No puedes enviar una factura de una venta eliminada"
                                  );
                                }
                              }}
                            >
                              <Send color="white" />
                            </CButton>
                          </CTooltip>

                          <CTooltip
                            content={<span>Descargar Factura en PDF</span>}
                          >
                            <CButton
                              size="sm"
                              color="primary"
                              disabled={downloading}
                              onClick={() => {
                                downloadInvoice(invoice);
                              }}
                            >
                              <CloudDownload />
                            </CButton>
                          </CTooltip>

                          {canCreateCreditNote && (
                            <CTooltip
                              content={<span>Crear Nota de Crédito</span>}
                            >
                              <CButton
                                size="sm"
                                color="warning"
                                className="ml-2"
                                onClick={() => {
                                  if (
                                    sale.data.deletedAt === null ||
                                    user?.isAdmin
                                  ) {
                                    creditNoteModalRef.current?.open(invoice);
                                  } else {
                                    alert(
                                      "No puedes crear una nota de crédito en una venta eliminada"
                                    );
                                  }
                                }}
                              >
                                <Redo />
                              </CButton>
                            </CTooltip>
                          )}

                          {!invoice.afipInvoice.packId &&
                            sale.data.store.type === StoreType.MercadoLibre && (
                              <CButton
                                size="sm"
                                className="ml-2"
                                disabled={attaching}
                                color="success"
                                onClick={() => {
                                  attachMutation({
                                    variables: {
                                      id: sale.data.id,
                                      cae: invoice.afipInvoice.cae,
                                    },
                                  });
                                }}
                              >
                                <CloudUpload />
                              </CButton>
                            )}
                        </td>
                      );
                    },

                    details: (item: Item) =>
                      (item as SaleInvoice).creditNotes.length > 0 && (
                        <CTable>
                          <CTableBody>
                            {(item as SaleInvoice).creditNotes?.map(
                              (note, index) => (
                                <CTableRow key={index}>
                                  <CTableDataCell style={{ width: 182 }}>
                                    <ArrowRight />
                                    <span className="ml-2">{note.cae}</span>
                                  </CTableDataCell>
                                  <CTableDataCell>
                                    {note.afipInvoice?.voucher?.name}
                                  </CTableDataCell>
                                  <CTableDataCell className="text-right">
                                    {dateFormat(
                                      note.createdAt,
                                      "dd/MM/yyyy HH:mm"
                                    )}
                                  </CTableDataCell>
                                  <CTableDataCell
                                    className="text-center"
                                    style={{ width: 120 }}
                                  >
                                    {note.emailSent ? (
                                      <CTooltip
                                        content={`Enviado el ${dateFormat(
                                          note.emailSent,
                                          "dd/MM/yyyy HH:mm"
                                        )}`}
                                      >
                                        <CheckIcon />
                                      </CTooltip>
                                    ) : (
                                      <XIcon />
                                    )}
                                  </CTableDataCell>
                                  <CTableDataCell className="text-right">
                                    <div className="d-flex align-items-center justify-content-end">
                                      <CTooltip
                                        content={
                                          <span>
                                            Enviar Nota de Crédito por Email
                                          </span>
                                        }
                                      >
                                        <CButton
                                          size="sm"
                                          color="info"
                                          className="mr-2"
                                          onClick={() => {
                                            if (sale.data.deletedAt === null) {
                                              sendInvoiceModalRef.current?.open(
                                                note
                                              );
                                            } else {
                                              alert(
                                                "No puedes enviar una nota de crédito de una venta eliminada"
                                              );
                                            }
                                          }}
                                        >
                                          <Send color="white" />
                                        </CButton>
                                      </CTooltip>

                                      <CTooltip
                                        content={
                                          <span>
                                            Descargar Nota de Crédito en PDF
                                          </span>
                                        }
                                      >
                                        <CButton
                                          size="sm"
                                          color="primary"
                                          disabled={downloading}
                                          onClick={() => {
                                            downloadInvoice(
                                              note,
                                              false,
                                              "credit"
                                            );
                                          }}
                                        >
                                          <CloudDownload />
                                        </CButton>
                                      </CTooltip>
                                    </div>
                                  </CTableDataCell>
                                </CTableRow>
                              )
                            )}
                          </CTableBody>
                        </CTable>
                      ),
                  }}
                  tableProps={{
                    striped: true,
                  }}
                />
                <Toast ref={toastRef} text="Error al facturar" />
              </CCardBody>
            </CCard>
          </CCol>
        )}
      </CRow>

      <CalendarModal
        withTime
        loading={loading}
        onSubmit={(date: string) => {
          changeDateMutation({
            variables: {
              id: sale.data.id,
              input: {
                date: new Date(date),
              },
            },
          });
        }}
        ref={calendarModalRef}
      />

      {!isCompletedSale && !isDeletedSale && (
        <>
          <CancelSaleModal ref={cancelSaleModalRef} />

          <AddPaymentModal
            ref={addPaymentModalRef}
            refetch={refetch}
            rest={rest}
          />
        </>
      )}

      {isCompletedSale && !isDeletedSale && (
        <>
          <DeleteSaleModal ref={deleteSaleModalRef} />

          <InvoicingModal ref={invoicingModalRef} />

          <SendInvoiceModal
            customer={sale.data.customer}
            ref={sendInvoiceModalRef}
            refetch={refetch}
          />

          <CreditNoteModal ref={creditNoteModalRef} />
        </>
      )}

      {!sale.data.customer?.id && <CustomersModal ref={customersModalRef} />}
    </>
  );
};

export default SaleScreen;
